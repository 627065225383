exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-investment-tsx": () => import("./../../../src/pages/AboutInvestment.tsx" /* webpackChunkName: "component---src-pages-about-investment-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-favorites-tsx": () => import("./../../../src/pages/Favorites.tsx" /* webpackChunkName: "component---src-pages-favorites-tsx" */),
  "component---src-pages-flats-tsx": () => import("./../../../src/pages/Flats.tsx" /* webpackChunkName: "component---src-pages-flats-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/Gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-garages-tsx": () => import("./../../../src/pages/Garages.tsx" /* webpackChunkName: "component---src-pages-garages-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/Location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-maxfliz-tsx": () => import("./../../../src/pages/Maxfliz.tsx" /* webpackChunkName: "component---src-pages-maxfliz-tsx" */),
  "component---src-pages-mother-website-tsx": () => import("./../../../src/pages/MotherWebsite.tsx" /* webpackChunkName: "component---src-pages-mother-website-tsx" */),
  "component---src-pages-multi-building-garages-tsx": () => import("./../../../src/pages/MultiBuildingGarages.tsx" /* webpackChunkName: "component---src-pages-multi-building-garages-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/Partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/Promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-pages-safe-credit-tsx": () => import("./../../../src/pages/SafeCredit.tsx" /* webpackChunkName: "component---src-pages-safe-credit-tsx" */),
  "component---src-pages-wawelo-finances-tsx": () => import("./../../../src/pages/WaweloFinances.tsx" /* webpackChunkName: "component---src-pages-wawelo-finances-tsx" */),
  "component---src-templates-flat-tsx": () => import("./../../../src/templates/flat.tsx" /* webpackChunkName: "component---src-templates-flat-tsx" */),
  "component---src-templates-floor-tsx": () => import("./../../../src/templates/floor.tsx" /* webpackChunkName: "component---src-templates-floor-tsx" */)
}

